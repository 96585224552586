import React from "react"
import { Router } from "@reach/router"
import Confirm from "../components/Confirmation/Confirm"

const Confirmation = () => {
  return (
    <Router>
      <Confirm path="account-activatie/:email" />
    </Router>
  )
}

export default Confirmation
