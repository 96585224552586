import React, { useEffect } from "react"
import { useOAuthTokens } from "src/hooks/useOAuthTokens"
import { useLocation } from "@reach/router"
import queryString from "query-string"
import { homeRoute, profileRoute } from "../../routes/routes"
import { navigate } from "gatsby"
import { useGuestApi } from "../../hooks/useApi"
import withGuestLayout from "../../hoc/withGuestLayout"
import { useVotes } from "../../hooks/useVotes"
import { useRedirect } from "../../hooks/useRedirect"

function Confirm({ email }) {
  const { setOAuthTokens, accessToken } = useOAuthTokens()
  const location = useLocation()
  const {redirectToRoute} = useRedirect();
  const { getMagicLinkToken, createVote, getMeByAccessToken, createQuestions } = useGuestApi()
  const {
    votes,
    lastVotingUrl,
    clearVotingVotes,
    clearLastVoting,
    votingSuggestion,
    clearVotingSuggestions,
    questions,
    clearVotingQuestions,
  } = useVotes()

  const logAnalytics = () => {
    window.dataLayer = window.dataLayer || []
    window.dataLayer.push({
      event: "play_nostalgie_register",
      login_registration_step: 'account_created_confirmation'
    })
  }

  useEffect(() => {
    const queries = queryString.parse(location.search)
    if (queries.access_token) {
      getMagicLinkToken(queries.access_token).then(response => {
        if(response?.data?.access_token?.access_token) {
          setOAuthTokens({accessToken: response.data.access_token.access_token});
        }
      })
    }
  }, [])

  const formatQuestionsData = (questions, voterId) => {
    return questions.map(question => {
      return {
        voter_id: voterId,
        question_id: question.question_id,
        answer: question.answer,
      }
    }).filter((e) => e);
  }

  useEffect(() => {
    if (accessToken) {
      logAnalytics()
      if (votes.length > 0 && lastVotingUrl) {
        getMeByAccessToken(accessToken).then(response => {
          if (response && accessToken) {
            let voteIds = []
            votes.map(vote => {
              voteIds.push(vote.id)
            })
            const obj = {
              voter_id: response.id,
              list_item_ids: voteIds,
              comment: votingSuggestion,
            }
            createQuestions(formatQuestionsData(questions, response.id)).then(() => {
              clearVotingQuestions()
            })
            createVote(obj).then(() => {
              clearVotingVotes()
              clearVotingSuggestions()
              navigate(lastVotingUrl)
              clearLastVoting()
              clearVotingQuestions()
            })
          }
        })
      } else if(redirectToRoute === 'reset-password') {
        navigate(profileRoute)
      } else {
        navigate(homeRoute)
      }
    }
  }, [accessToken])

  return null
}

export default withGuestLayout(Confirm, {
  page: "Account activatie",
  shareImage: "",
  shareDescription: "",
})
